import setCoverages from './setCoverages';
import curateSpecialtyPayload from './curateSpecialtyPayload';

/**
 * Adjusts the state object to send only what we need
 * @param {Object} state - the full state object
 * @returns a curated state object
 */
const curateState = (state, useEnrolledPlans) => {
    const {
        specialtyCriteria: {
            dentalCensusSummary, lifeCensusSummary, visionCensusSummary,
            employeeOnly, employeeWithSpouse, employeeWithChild,
            employeeWithFamily, sicObj, stdCensusSummary, ltdCensusSummary,
        },
        dental: stateDental, vision: stateVision, life: stateLife, lifeDep: stateLifeDep, std: stateSTD, ltd: stateLTD,
        suppLife:stateSuppLife,
        dental: { selectedPlans: dentalSelectedPlans },
        vision: { selectedPlans: visionSelectedPlans },
        life: { selectedPlans: lifeSelectedPlans },
        lifeDep: { selectedPlans: deLifeSelectedPlans },
        std: { selectedPlans: stdSelectedPlans },
        ltd: { selectedPlans: ltdSelectedPlans },
        suppLife:{selectedPlans: suppLifeSelectedPlans},
        quotes: { selectedPlans, contributions, filterCriteria, optionalBenefitRiders },
        overall,
        overall: {
            platform, quickQuoteRequest, source,
        },
        companyProfile: {
            effectiveDate, locations, companyName, memberGroupId, sicData,
            primaryContact, sicCode, selectedProducts, employees: stateEmployees,
        },
        rules: {
            enrollment: {
                enrolledPlans, enrolledDentalPlans, enrolledVisionPlans,
                enrolledLifePlans, enrolledDependentLifePlans, enrolledSTDPlans, enrolledLTDPlans
            }
        }
    } = state;

    const enrolledMedicalPlans = Object.keys(enrolledPlans).length > 0 ? Object.keys(enrolledPlans).map((key) => enrolledPlans[key]).flat() : [];
    const dental = curateSpecialtyPayload(stateDental, enrolledDentalPlans, useEnrolledPlans);
    const vision = curateSpecialtyPayload(stateVision, enrolledVisionPlans, useEnrolledPlans);
    const life = curateSpecialtyPayload(stateLife, enrolledLifePlans, useEnrolledPlans);
    const lifeDep = curateSpecialtyPayload(stateLifeDep, enrolledDependentLifePlans, useEnrolledPlans);
    const std = curateSpecialtyPayload(stateSTD, enrolledSTDPlans, useEnrolledPlans);
    const ltd = curateSpecialtyPayload(stateLTD, enrolledLTDPlans, useEnrolledPlans);
    const suppLife = curateSpecialtyPayload(stateSuppLife, [], useEnrolledPlans);

    const employees = setCoverages(selectedProducts, stateEmployees, selectedPlans,
        dentalSelectedPlans, visionSelectedPlans, lifeSelectedPlans, deLifeSelectedPlans, stdSelectedPlans, ltdSelectedPlans, overall);

    return {
        specialtyCriteria: {
            dentalCensusSummary,
            lifeCensusSummary,
            visionCensusSummary,
            employeeOnly,
            employeeWithSpouse,
            employeeWithChild,
            employeeWithFamily,
            sicObj,
            stdCensusSummary,
            ltdCensusSummary,
        },
        quotes: {
            contributions,
            selectedPlans: useEnrolledPlans ? enrolledMedicalPlans : selectedPlans,
            filterCriteria,
            optionalBenefitRiders
        },
        overall: {
            platform,
            quickQuoteRequest,
            source,
        },
        companyProfile: {
            effectiveDate,
            locations,
            employees,
            companyName,
            memberGroupId,
            sicCode,
            sicData,
            primaryContact,
            selectedProducts,
        },
        dental,
        vision,
        life,
        lifeDep,
        std,
        ltd,
        suppLife
    };
};

export default curateState;
